import { useOrder } from "store";
import {
  useSurvey,
  useChangedItemsAtom,
  getChangedItemQuantity,
} from "components/pages/BelongingsPage/tools/surveyAtom";
import type { Survey } from "models/Survey";
import { useEstimatedOverageCost } from "./useEstimatedOverageCost";

const round = (n: number) => Math.round(n);

type SurveyCalculation = {
  hasSurvey: boolean;
  estimatedWeight: number;
  estimatedWeightPercentage: number;
  overage: number;
  hasOverage: boolean;
};

const defaultCalculation: SurveyCalculation = {
  hasSurvey: false,
  estimatedWeight: 0,
  estimatedWeightPercentage: 0,
  overage: 0,
  hasOverage: false,
};

const hasChangedItems = (items: Record<string, number>) => {
  const changedItemsVals = Object.values(items).filter((item) => item !== 0);
  return changedItemsVals.length !== 0;
};

type CalculateParams = {
  survey?: Survey;
  entitlementAmount: number;
  changedItems: Record<string, number>;
};
export function calculateSurveyStats({
  survey,
  entitlementAmount,
  changedItems,
}: CalculateParams) {
  if (!survey) {
    return defaultCalculation;
  }
  const weight = hasChangedItems(changedItems)
    ? survey.itemsNonProGear.reduce((totalWeight, item) => {
        const changedQuantity = getChangedItemQuantity(
          changedItems,
          item.roomId ?? "",
          item.id
        );
        // These seem to sometimes come back as a string
        // TODO: confirm what type they actually should be
        const cube =
          typeof item.cube === "string" ? parseFloat(item.cube) : item.cube;
        const weight =
          typeof item.weight === "string"
            ? parseFloat(item.weight)
            : item.weight;
        const weightPerItem = weight ?? cube * 7;
        return totalWeight + (item.going + changedQuantity) * weightPerItem;
      }, 0) ?? 0
    : survey.estimatedWeight;
  const overage = weight - entitlementAmount;
  const hasOverage = overage > 0;
  const estimatedWeightPercentage = Math.ceil(
    (weight / entitlementAmount) * 100
  );

  return {
    hasSurvey: true,
    estimatedWeight: round(weight),
    estimatedWeightPercentage,
    overage: round(overage),
    hasOverage,
  };
}

export function useSurveyCalculations(
  /**
   * If true, will factor in the changed items from the weight wizard
   */
  includeChanges?: boolean
): SurveyCalculation {
  const order = useOrder();
  const survey = useSurvey();
  const [{ changedItems }] = useChangedItemsAtom();

  const { hasOverage, estimatedWeight, ...rest } = calculateSurveyStats({
    survey,
    entitlementAmount: order?.entitlement?.totalWeight,
    changedItems: includeChanges ? changedItems : {},
  });
  return {
    ...rest,
    hasOverage,
    estimatedWeight,
  };
}
