import {
  FormattedMessage,
  translationKeys,
  useIntl,
} from "services/translation";
import classnames from "classnames";
import { HighlightUnbackedData } from "components/common/HighlightUnbackedData";
import { useOrder } from "services/orders/OrderGuard";
import { formatNumber } from "tools/formatNumber";
import scss from "../Belongings.module.scss";

interface EntitlementAllownaceProps {
  gaugeLabelId: translationKeys;
  weightDetailsId: translationKeys;
  allowanceTitleId: translationKeys;
  allowanceAmountTitleId: translationKeys;
  allowanceAmountId: translationKeys;
  estimatedWeightPercentage: number;
}

export function EntitlementAllowance(props: EntitlementAllownaceProps) {
  const {
    gaugeLabelId,
    weightDetailsId,
    allowanceTitleId,
    allowanceAmountTitleId,
    allowanceAmountId,
    estimatedWeightPercentage,
  } = props;
  const { formatMessage } = useIntl();
  const order = useOrder();
  return (
    <div
      className={classnames(scss.entitlement_allowance)}
      aria-label={formatMessage({
        id: gaugeLabelId,
      })}
      aria-details={formatMessage(
        {
          id: weightDetailsId,
        },
        {
          estimatedWeightPercentage,
        }
      )}
    >
      <div className={classnames(scss.label)}>
        <FormattedMessage id={allowanceTitleId} />
      </div>
      <div
        aria-label={formatMessage({
          id: allowanceAmountTitleId,
        })}
      >
        <span
          className={classnames(scss.amount)}
          aria-label={formatMessage({
            id: allowanceAmountId,
          })}
        >
          <HighlightUnbackedData>
            {formatNumber(order?.entitlement?.totalWeight)}
          </HighlightUnbackedData>
        </span>
        <span className={classnames(scss.measure)}>
          {order.customer.weightUnits}
        </span>
      </div>
    </div>
  );
}
