import { Gauge } from "components/common/ui/Gauge";
import { Panel } from "components/common/ui/Panel";
import { EntitlementAllowance, EstimatedWeight } from "./";
import { FormattedMessage, useIntl } from "services/translation";
import classnames from "classnames";
import { useSurveyCalculations } from "hooks";
import scss from "../Belongings.module.scss";
import { Box } from "@mui/system";
import { Stack, Typography } from "@mui/material";
import { CostSummary } from "./CostSummary";
import { Card } from "components/common";
import { ProgearWeightsCard } from "./ProgearWeightsCard";

export const WeightWizard = () => {
  const { formatMessage } = useIntl();

  const { estimatedWeight, estimatedWeightPercentage } =
    useSurveyCalculations(true);

  return (
    <div aria-label={formatMessage({ id: "ariaLabels.homeEstimation" })}>
      <div>
        <div>
          <Panel
            className={classnames(scss.weightWizardPanel)}
            ariaLabel="Weight Wizard"
          >
            <Typography variant="xlHeading">
              <FormattedMessage id="gauge.title" />
              <span className={scss.red_star}>*</span>
            </Typography>
            <div className={classnames(scss.estimation_container)}>
              <Box mb="12px">
                <Gauge value={estimatedWeightPercentage}>
                  <EstimatedWeight estimatedWeight={estimatedWeight} />
                  <EntitlementAllowance
                    allowanceAmountId="ariaLabels.amount"
                    allowanceAmountTitleId="ariaLabels.entitlementAllowance"
                    allowanceTitleId="gauge.entitlementAllowance"
                    gaugeLabelId="ariaLabels.weightGauge"
                    weightDetailsId="ariaDetails.estimatedWeight"
                    estimatedWeightPercentage={estimatedWeightPercentage}
                  />
                </Gauge>
              </Box>
              <CostSummary mode="ADJUSTED_SURVEY" />
            </div>
          </Panel>
          <ProgearWeightsCard />
          <Stack gap={"16px"}>
            <div className={scss.disclaimer}>
              <div className={scss.red_star}>*</div>
              <div>
                <FormattedMessage id="gauge.disclaimer" />
              </div>
            </div>
            <div className={scss.disclaimer}>
              <div className={scss.red_star}>*</div>
              <div>
                <FormattedMessage id="gauge.disclaimer2" />
              </div>
            </div>
            <div className={scss.disclaimer}>
              <div className={scss.red_star}>*</div>
              <div>
                <FormattedMessage id="gauge.disclaimer3" />
              </div>
            </div>
          </Stack>
        </div>
      </div>
    </div>
  );
};
