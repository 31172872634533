import { stringToPlainDate, Temporal } from "@movehq/datetime";
import { useOrder } from "store";
import { SurveyItem } from "models";

export const useIsLoadPlanned = () => {
  const { plannedLoadDate } = useOrder();
  return Boolean(plannedLoadDate);
};

export const useDaysToLoadDate = () => {
  const { plannedLoadDate } = useOrder();
  if (!plannedLoadDate) {
    return -1;
  }
  const loadDate = stringToPlainDate(plannedLoadDate);
  return Temporal.Now.plainDateISO().until(loadDate).total({ unit: "days" });
};

export const useWithinLockWindow = () => {
  const isLoadPlanned = useIsLoadPlanned();
  const days = useDaysToLoadDate();
  if (!isLoadPlanned) {
    return false;
  }
  return isLoadPlanned ? days <= 5 : false;
};

export const useIsItemHeavy = (item?: SurveyItem) => {
  if (!item?.cube) {
    return false;
  }
  const cube: number =
    typeof item?.cube === "string" ? parseFloat(item.cube) : item?.cube;
  return cube >= 20;
};

export const useItemQuantity = (item?: SurveyItem) => {
  return item ? item.going + item.notGoing : 0;
};
