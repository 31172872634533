import { useEstimatedMoveCost } from "hooks/useEstimatedOverageCost";
import classnames from "classnames";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { FormattedMessage, useIntl } from "services";
import scss from "../Belongings.module.scss";
import { Alert, Box, CircularProgress, Typography } from "@mui/material";
import { useSurveyCalculations } from "hooks";
import { formatPriceUsd } from "components/common/layouts/InventoryPageLayout/components/ClaimsDisplay/ClaimsList/moneyConversions";

interface Props {
  mode?: "SURVEY" | "ADJUSTED_SURVEY";
}

export const CostSummary: React.FC<Props> = ({ mode } = { mode: "SURVEY" }) => {
  const { formatMessage } = useIntl();
  const {
    hasOverage: hasWeightOverage,
    overage,
    estimatedWeight,
  } = useSurveyCalculations(mode === "ADJUSTED_SURVEY");

  const { data, isLoading, error } = useEstimatedMoveCost({
    totalWeight: estimatedWeight,
  });

  if (isLoading) {
    return (
      <Box borderRadius={"8px"} border="1px solid #DDD" padding="16px 0px">
        <CircularProgress />
        <div>
          <Typography align="center"> Calculating move costs...</Typography>
        </div>
      </Box>
    );
  }

  if (!data || error) {
    return (
      <Box>
        <Alert severity="error">Error calculating costs</Alert>
      </Box>
    );
  }
  const hasCostOverage = data.excess_cost > 0;

  return hasCostOverage ? (
    <Box>
      <div className={classnames(scss.overage)}>
        <div
          className={classnames(scss.overage_warning)}
          aria-label={formatMessage({
            id: "ariaLabels.entitlementOverageWarning",
          })}
        >
          <ErrorOutlineIcon />
          <div>
            <div>
              {hasWeightOverage ? (
                <FormattedMessage id="gauge.alert.exceedsEntitlement" />
              ) : (
                // If there is no weight overage, we assume that the cost average
                // must have occured due to an address change
                <FormattedMessage id="guage.alert.extraCostWithinEntitlement" />
              )}
            </div>

            <strong>
              <FormattedMessage
                id="gauge.overage"
                values={{
                  overage,
                  estimatedCost: `${formatPriceUsd(data.excess_cost)}`,
                }}
              />
            </strong>
          </div>
        </div>
      </div>
    </Box>
  ) : (
    <div className={classnames(scss.within_entitlement)}>
      <div
        className={classnames(scss.within_entitlement_content)}
        aria-label={formatMessage({
          id: "ariaLabels.withinEntitlementMessage",
        })}
      >
        <CheckCircleOutlineIcon />
        {hasWeightOverage ? (
          <FormattedMessage id="gauge.alert.exceedsEntitlementNoExtra" />
        ) : (
          <FormattedMessage id="gauge.alert.withinEntitlement" />
        )}
      </div>
    </div>
  );
};
