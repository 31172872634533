import classnames from "classnames";
import { FormattedMessage } from "services/translation/FormattedMessage";
import { useIntl } from "services/translation/Intl";
import { Card } from "components/common/ui/Card";
import { Counter } from "components/common/ui/Counter";
import { HighlightUnbackedData } from "components/common/HighlightUnbackedData";
import scss from "./RoomCard.module.scss";
import {
  useSurveyRoom,
  useSurveyItem,
  useChangedItemQuantityAtom,
} from "../../tools/surveyAtom";
import { useIsItemHeavy } from "../../tools/hooks";
import { useSurveillanceMode } from "hooks/useSurveillanceMode";
import { HeavyIcon } from "../HeavyIcon";
import { Box, Chip, Stack, Tooltip, Typography } from "@mui/material";

interface RoomCardProps {
  name: string;
  roomId: string;
}

export const useItem = (roomId: string, itemId: string) => {
  const item = useSurveyItem(itemId);
  const surveillanceMode = useSurveillanceMode();

  const [changedQuantity, setChangedQuantity] = useChangedItemQuantityAtom(
    roomId,
    itemId
  );
  const isHeavy = useIsItemHeavy(item);
  const isGunSafe = !!(item?.definitionId === "2248");
  const isProGear = !!(item?.category === "MEMBER_PROGEAR");
  const isSpouseProGear = !!(item?.category === "SPOUSE_PROGEAR");
  const going = (item?.going ?? 0) + changedQuantity;
  const notGoing = (item?.notGoing ?? 0) - changedQuantity;
  const incrementDisabled =
    surveillanceMode ||
    notGoing <= 0 ||
    isGunSafe ||
    isProGear ||
    isSpouseProGear;
  const decrementDisabled =
    surveillanceMode || going <= 0 || isGunSafe || isProGear || isSpouseProGear;

  const decrement = () => {
    setChangedQuantity(changedQuantity - 1);
  };
  const increment = () => {
    setChangedQuantity(changedQuantity + 1);
  };

  return {
    incrementDisabled,
    increment,
    decrementDisabled,
    decrement,
    quantity: (item?.going ?? 0) + changedQuantity,
    name: item?.name,
    isHeavy,
    changedQuantity,
    isProGear,
    isSpouseProGear,
    isGunSafe,
  };
};
function RoomItem({
  itemId,
  roomId = "",
}: {
  itemId: string;
  roomId: string | undefined;
}) {
  const {
    incrementDisabled,
    increment,
    decrementDisabled,
    decrement,
    quantity,
    name,
    isHeavy,
    changedQuantity,
    isProGear,
    isSpouseProGear,
    isGunSafe,
  } = useItem(roomId, itemId);
  const { formatMessage } = useIntl();

  if (!name) return <></>;

  return (
    <tr aria-label={`${name}: ${quantity}`} tabIndex={0}>
      <th>
        <Stack direction="row" spacing={1}>
          <Typography variant="mBody">{name}</Typography>
          {isProGear && <Chip label="PG" size="small" variant="teal" />}
          {isSpouseProGear && (
            <Chip label="SG" size="small" variant="deepPurple" />
          )}
        </Stack>
      </th>
      <td
        aria-label={formatMessage({
          id: "ariaLabels.amountRemoved",
        })}
        className={scss.removed_cell}
      >
        {changedQuantity}
      </td>
      <td className={scss.heavy_cell}>{isHeavy && <HeavyIcon />}</td>
      <td className={scss.quantity_cell}>
        <HighlightUnbackedData block={true}>
          <Tooltip
            title={
              isProGear || isSpouseProGear || isGunSafe
                ? formatMessage({
                    id: "reviewYourBelongings.proGearGunSafeTooltip",
                  })
                : undefined
            }
          >
            <Box>
              <Counter
                value={quantity}
                decrement={decrement}
                decrementDisabled={decrementDisabled}
                increment={increment}
                incrementDisabled={incrementDisabled}
              />
            </Box>
          </Tooltip>
        </HighlightUnbackedData>
      </td>
    </tr>
  );
}

export function RoomCard(props: RoomCardProps) {
  const { name, roomId } = props;
  const room = useSurveyRoom(roomId);
  const { formatMessage } = useIntl();

  return (
    <div aria-label={name} key={`room-${roomId}`}>
      <Card
        title={<HighlightUnbackedData>{name}</HighlightUnbackedData>}
        variant="large"
        accordion={true}
        aria-label={name}
      >
        <table className={classnames(scss.table)}>
          <thead>
            <tr>
              <th>
                <Typography variant="xsHeading">
                  <FormattedMessage id="reviewYourBelongings.itemName" />
                </Typography>
              </th>
              <th className={classnames("text-center")}>
                <Typography variant="xsHeading">
                  <FormattedMessage id="reviewYourBelongings.changed" />
                </Typography>
              </th>
              <th className={classnames("text-center")}>
                <Typography variant="xsHeading">
                  <FormattedMessage id="reviewYourBelongings.heavy" />
                </Typography>
              </th>
              <th className={classnames("text-center")}>
                <Typography variant="xsHeading">
                  <FormattedMessage id="reviewYourBelongings.quantity" />
                </Typography>
              </th>
            </tr>
          </thead>
          <tbody
            aria-label={formatMessage(
              { id: "ariaLabels.roomItems" },
              { roomName: name }
            )}
          >
            {room &&
              room?.map(
                (item) =>
                  item && (
                    <RoomItem
                      itemId={item?.id}
                      key={`${item?.id}-${roomId}`}
                      roomId={roomId}
                    />
                  )
              )}
          </tbody>
        </table>
      </Card>
    </div>
  );
}
